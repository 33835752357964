import { FAQ_URL, OPERATIONAL_MANUAL_URL, OPERATIONAL_MANUAL_URL_ENGLISH } from '@/config/constant'
import i18n from '@/locale'

export interface MenuInfoItem {
    rootName?: string
    name?: any
    routeName?: string
    iconDefault?: string
    childs?: MenuInfoItem[]
    disabled?: boolean
    url?: string
    showChilds?: boolean
}

export const dashboardMenu: MenuInfoItem[] = [
    // {
    //     name: '工作台',
    //     routeName: 'Home',
    //     iconDefault: `ri-home-smile-line`,
    // },
    {
        name: i18n.t('menus.projectList'),
        routeName: 'ProjectList',
        iconDefault: `ri-stack-line`
    },
    {
        name: i18n.t('menus.projectAssistant'),
        routeName: 'ProjectAssistant',
        iconDefault: `ri-share-fill`
    },
    {
        name: i18n.t('menus.memberList'),
        routeName: 'MemberList',
        iconDefault: `ri-team-line`
    },
    {
        name: i18n.t('menus.helpDocumentation'),
        routeName: 'HelpDocumentation',
        iconDefault: `ri-book-2-line`,
        showChilds: false,
        childs: [
            {
                name: i18n.t('menus.FAQ'),
                url: FAQ_URL
            },
            {
                name: i18n.t('menus.operationDocument'),
                url: OPERATIONAL_MANUAL_URL
            },
            {
                name: i18n.t('menus.operationDocumentEnglish'),
                url: OPERATIONAL_MANUAL_URL_ENGLISH
            }
        ]
    }
]

export const projectMenu: MenuInfoItem[] = [
    {
        name: i18n.t('edit'),
        rootName: 'detail',
        iconDefault: `ri-edit-line`,
        childs: [
            {
                name: i18n.t('projectMenus.projectIntroduce'),
                routeName: 'ProjectIntroduce'
            },
            {
                name: i18n.t('projectMenus.projectTime'),
                routeName: 'ProjectTime'
            },
            {
                name: i18n.t('projectMenus.projectWordCloud'),
                routeName: 'ProjectWordCloud'
            },
            {
                name: i18n.t('projectMenus.projectInvite'),
                routeName: 'ProjectInvite'
            },
            {
                name: i18n.t('projectMenus.projectSurvey'),
                routeName: 'ProjectSurvey'
                // disabled: true
            },
            {
                name: i18n.t('projectMenus.projectGroup'),
                routeName: 'ProjectGroup'
                // disabled: true
            },
            {
                name: i18n.t('projectMenus.projectQuota'),
                routeName: 'ProjectQuota'
            },
            {
                name: i18n.t('projectMenus.projectTestGroup'),
                routeName: 'ProjectTestGroup'
            }
        ]
    },
    {
        name: i18n.t('projectMenus.projectLive'),
        routeName: 'ProjectLive',
        iconDefault: `ri-question-answer-line`
        // disabled: true
    }
]
