import { defineStore } from 'pinia'
import { pinia } from '@/pinia'
import { getSegmentsApi } from '@/api/project'
import { testGroupsApi ,getTestGroupInfoApi,getAiSettingsApi,getProjectSettingsApi} from '@/api/project'
import { showError } from '../../utils/common'
import { getConversationInfoApi } from '@/api/conversation'
import { ProjectStatus } from '@/api/project/model'
import { LocaleType } from '@/interface/common';
import { AnyNsRecord } from 'dns'

interface ProjectState {
    segment: {
        [key: string]: any[];
    }
    testGroups: {
        [key: string]: any[];
    }
    analyzeSegment: {
        [key: string]: any[];
    }
    filterSegment: {
        [key: string]: any[];
    }
    answerSegment: {
        [key: string]: any;
    }
    answerFilterSegment: {
        [key: string]: any;
    }
    surveySaveState: number
    dataDriver: string
    projectInfo: any,
    analyzeConceptPoll:{
        [key: string]: any;
    },
    analyzeConceptAskRank:{
        [key: string]: any;
    },
    analyzeConceptAskExperience:{
        [key: string]: any;
    },
    analyzeConceptAskOpinion:{ [key: string]: any;},
    analyzeConceptBranch:{ [key: string]: any;},
}

let timer: any = null

export const useProjectStore = defineStore({
    id: 'project',
    state: (): ProjectState => ({
        segment: {},
        testGroups:{},
        analyzeSegment: {},
        filterSegment: {},
        answerSegment: {},
        answerFilterSegment: {},
        surveySaveState: 0,
        dataDriver: '',
        projectInfo: {},
        analyzeConceptPoll: {},
        analyzeConceptAskRank:{},
        analyzeConceptAskExperience:{},
        analyzeConceptAskOpinion:{},
        analyzeConceptBranch:{}
    }),
    getters: {},
    actions: {
        async getProjectInfo(projectUuId: string) {
            if (timer) {
                clearTimeout(timer)
            }
            if (projectUuId) {
                try {
                    const data: any = await getConversationInfoApi({
                        uuid: projectUuId
                    })
                    let testGroupInfo=null
                    if(data.test_group){
                        testGroupInfo  = await getTestGroupInfoApi({
                            uuid: projectUuId,
                            testGroupId:data.test_group.id
                        })
                    }
                    const aiSettings = await getAiSettingsApi()
                 
                    
                    let isAutoSend=false
                    let autoSendUsed=false
                    let autoData=  await getProjectSettingsApi({
                          uuid: projectUuId
                      })
                      isAutoSend=autoData.auto_send
                      autoSendUsed=autoData.auto_send_used
                    this.projectInfo = {
                        ...data,
                        test_group_info:testGroupInfo,
                        detail_language: data.detail_language || LocaleType.en_US,
                        dataDriver: data.status === ProjectStatus.End ? 'mysql' : '',
                        ai_settings:aiSettings,
                        isAutoSend:isAutoSend,
                        autoSendUsed:autoSendUsed

                    }
                    this.rollGetProjectInfo()
                } catch (error) {
                    showError(error)
                }
            }
        },
        rollGetProjectInfo() {
            if (
                [
                    ProjectStatus.Pending,
                    ProjectStatus.Start,
                    ProjectStatus.Published
                ].includes(this.projectInfo.status)
            ) {
                timer = setTimeout(
                    () => this.getProjectInfo(this.projectInfo.project_uuid),
                    3000
                )
            }
        },
        resetProjectInfo() {
            this.projectInfo = {}
            clearTimeout(timer)
        },

        handleSurveySaveState() {
            this.surveySaveState = 2
            setTimeout(() => {
                if (this.surveySaveState === 2) {
                    this.surveySaveState = 0
                }
            }, 2000)
        },
        async getProjectSegments(projectUuId: string, observer = false) {
            if (!projectUuId) {
                return []
            }
            if (this.segment[projectUuId]) {
                return this.segment[projectUuId]
            }
            try {
                const list = await getSegmentsApi({
                    uuid: projectUuId,
                    observer
                })
                this.segment[projectUuId] = list
            } catch (error) {
                showError(error)
            }
            return this.segment[projectUuId]
        },
        async updateProjectSegments(projectUuId: string, observer = false) {
            try {
                const list = await getSegmentsApi({
                    uuid: projectUuId,
                    observer
                })
                this.segment[projectUuId] = list
            } catch (error) {
                showError(error)
            }
        },
        async updateProjectTestGroup(projectUuId: string) {
            try {
                const list = await testGroupsApi({
                    uuid: projectUuId,
                })
                this.testGroups[projectUuId] = list
            } catch (error) {
                showError(error)
            }
        },
        async getProjectTestGroups(projectUuId: string) {
            if (!projectUuId) {
                return []
            }
            if (this.testGroups[projectUuId]) {
                return this.testGroups[projectUuId]
            }
            try {
                const list = await testGroupsApi({
                    uuid: projectUuId,
                })
                this.testGroups[projectUuId] = list
            } catch (error) {
                showError(error)
            }
            return this.testGroups[projectUuId]
        },
        getAnalyzeSegments(projectUuId: string, observer = false) {
            if (!projectUuId) {
                return []
            }
            if (this.analyzeSegment[projectUuId]) {
                return this.analyzeSegment[projectUuId]
            } else {
                return []
            }
        },
        updateAnalyzeSegments(
            projectUuId: string,
            segments: any[],
            observer = false
        ) {
            const segs = segments ?? []
            segs.forEach((seg: any) => {
                seg.collection = null
            })
            this.analyzeSegment[projectUuId] = segs
        },
        
        getFilterSegments(projectUuId: string, observer = false) {
            if (!projectUuId) {
                return []
            }
            if (this.filterSegment[projectUuId]) {
                return this.filterSegment[projectUuId]
            } else {
                return []
            }
        },
        updateFilterSegments(
            projectUuId: string,
            segments: any[],
            observer = false
        ) {
            const segs = segments ?? []
            segs.forEach((seg: any) => {
                seg.collection = null
            })
            this.filterSegment[projectUuId] = segs
        },
        
        getAnswerSegment(projectUuId: string, observer = false) {
            if (!projectUuId) {
                return null
            }
            if (this.answerSegment[projectUuId]) {
                return this.answerSegment[projectUuId]
            } else {
                return null
            }
        },
        updateAnswerSegment(projectUuId: string, segment: any) {
            segment.collection = null
            this.answerSegment[projectUuId] = segment
        },
        
        getAnswerFilterSegment(projectUuId: string, observer = false) {
            if (!projectUuId) {
                return null
            }
            if (this.answerFilterSegment[projectUuId]) {
                return this.answerFilterSegment[projectUuId]
            } else {
                return null
            }
        },
        updateAnswerFilterSegment(projectUuId: string, segment: any) {
            segment.collection = null
            this.answerFilterSegment[projectUuId] = segment
        },
        getAnalyzeConceptPoll(projectUuId: string,message_entity_id:number, observer = false) {
            if (!projectUuId) {
                return null
            }
            return this.analyzeConceptPoll[projectUuId+message_entity_id]
        },
        
        updateAnalyzeConceptPoll(projectUuId: string, analyzeObj: any,message_entity_id:number){
            this.analyzeConceptPoll[projectUuId+message_entity_id]= analyzeObj
        },
        getAnalyzeConceptAskRank(projectUuId: string,message_entity_id:number, observer = false) {
            if (!projectUuId) {
                return null
            }
            return this.analyzeConceptAskRank[projectUuId+message_entity_id]
        },
        updateAnalyzeConceptAskRank(projectUuId: string, analyzeObj: any,message_entity_id:number){
            this.analyzeConceptAskRank[projectUuId+message_entity_id]= analyzeObj
        },
        getAnalyzeConceptAskExperience(projectUuId: string,message_entity_id:number, observer = false) {
            if (!projectUuId) {
                return null
            }
            return this.analyzeConceptAskExperience[projectUuId+message_entity_id]
        },
        updateAnalyzeConceptAskExperience(projectUuId: string, analyzeObj: any,message_entity_id:number){
            this.analyzeConceptAskExperience[projectUuId+message_entity_id]= analyzeObj
        },
        getAnalyzeConceptAskOpinion(projectUuId: string,message_entity_id:number, observer = false) {
            if (!projectUuId) {
                return null
            }
            return this.analyzeConceptAskOpinion[projectUuId+message_entity_id]
        },
        updateAnalyzeConceptAskOpinion(projectUuId: string, analyzeObj: any,message_entity_id:number){
            this.analyzeConceptAskOpinion[projectUuId+message_entity_id]= analyzeObj
        },
        getAnalyzeConceptBranch(projectUuId: string,message_entity_id:number, observer = false) {
            if (!projectUuId) {
                return null
            }
            return this.analyzeConceptBranch[projectUuId+message_entity_id]
        },
        updateAnalyzeConceptBranch(projectUuId: string, analyzeObj: any,message_entity_id:number){
            this.analyzeConceptBranch[projectUuId+message_entity_id]= analyzeObj
        }


    }
})

// Need to be used outside the setup
export function useProjectStoreWithOut() {
    return useProjectStore(pinia)
}
