import config from '@/config'

const localeFromStorage = localStorage.getItem('locale');
const processedLocale = (localeFromStorage !== null)
  ? localeFromStorage.replace("-", "_")
  : (config.locale ?? '').replace("-", "_");

export const FAQ_URL = 'https://ktglbuc.sharepoint.com/:w:/s/MarketplaceGreaterChina/EUNKGR1JNMFIk3VHiBkfrhwB_YV63ujY3eS3PEu5eNEtYQ'

export const OPERATIONAL_MANUAL_URL = 'https://ktglbuc.sharepoint.com/:w:/s/MarketplaceGreaterChina/EXAm2klaljNEil06zqWm7ScBiWbVUdGw5X1pZn961rOTOQ'

export const OPERATIONAL_MANUAL_URL_ENGLISH = 'https://ktglbuc.sharepoint.com/:w:/s/MarketplaceGreaterChina/EVgVlgpZig9OltX0UEAdXBUBQHbOCkaBJRoCQkpx8QF_ww'
export const OUTLINE_IMPORT_TEMPLATE_URL = process.env[`VUE_APP_OUTLINE_IMPORT_TEMPLATE_URL`]+'_'+processedLocale+'.xlsx'
